export default {
    "props": {
        "alert": {
        }
    },
    "sx": (theme) => ({
        "width": "100%",
        "marginBottom": theme.spacing(4),
        "& > * + *": {
            "marginTop": theme.spacing(2),
        },
        "& > .MuiAlert-root": {
            "justifyContent": "center",
        },
    })
}