import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Alert from '@mui/material/Alert';

import defaults from './defaults';

const SimpleAlert = withSubtheme((props) => {
    const {
        className,
        classes,
        alert,
        message,
        severity
    } = props;

    const messageData = message ?? false;
    const severityData = severity ?? 'error';
    return(
        <>
            {messageData && <div className={classes ? classes : className}>
                <Alert severity={severityData} {...alert}>{messageData}</Alert>
            </div>
            }
        </>
    )
}, 'alertComponent', defaults)

export default SimpleAlert
